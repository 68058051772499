import React from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import InsightsCard from "../components/insights-card/insights-card";
import styled from "styled-components";
import breakpoints from "../utils/breakpoints";
import Hero from "../components/hero";

const FeaturedGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	& > div {
		margin-bottom: 3rem;
	}
	@media ${breakpoints.tabletSm} {
		grid-template-columns: repeat(3, 1fr);
		& > div {
			margin-bottom: 9.5rem;
		}
	}
	column-gap: 2.7rem
`

const NonFeaturedGrid = styled.div`
	margin-top: 10rem;
	@media ${breakpoints.tabletSm} {
		margin-top: 19.4rem;
	}
`

const Wrapper = styled.div`
	position: relative;
	// padding-top: 30vh;
	section {
		padding: 0 var(--grid-padding);
		@media ${breakpoints.tablet} {
		  padding: 0 calc(var(--grid-padding));
		}
		@media ${breakpoints.desktop} {
		  padding: 0 calc(var(--grid-padding) * 1.83);
		}
	  
	}
	h2 {
		font-weight: 300;
		font-size: 3.7rem;
		line-height: 4.9rem;
		margin: 0;
		margin-bottom: 7.5rem;
		font-family: 'Post Grotesk';
	}
	#content {
		margin-top: 7.4rem;
	}
`
const Divider = styled.hr`
	border: none;
	border-top: 1px solid #807E7E;
	box-shadow: none;
	margin: 0;
`

const InsightsPage = ({ location, pageContext, data }) => {
	// const { seo, entry } = pageContext.data;
	const insights = data.cms.entries.data
	const page = data.cms.page
	const nav = data.cms.nav
	const footer_nav = data.cms.footer_nav
	const global = data.cms.globalSet
	const seo = data.cms.SeoMeta
	const featured = insights.filter(i => !!i.featured).slice(0, 3)
	const unFeatured = insights.filter(i => !i.featured)
	const portfolio_companies = data.cms.portfolio_companies;
	const search = data.cms.search
	const team = data.cms.team

	return (
		<Layout seo={seo} nav={nav} footer_nav={footer_nav} global={global} search={search} team={team} portfolio_companies={portfolio_companies} location={location} theme="light" >
			<Seo
				title={seo.og_title}
				description={seo.og_description}
				meta={[
					{
						property: "og:image",
						content: "/images/og-image-1.jpg",
					},
				]}
			/>

			<Helmet>
				<script type="application/ld+json">
					{`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "SoftBank Latin America Fund",
                        "url": "https://latinamericafund.com",
                        "logo": "https://latinamericafund.com/images/og-image-1.jpg",
                        "alternateName": "The SoftBank Latin America Fund"
                    }
                    `}
				</script>
			</Helmet>
			{/* <Fade bottom distance="1.5rem" delay={800}> */}
				<Wrapper>
					{/* <Header>
						<div>
							<Headline>Insights</Headline>
						</div>
						<p>Perspectives from inside our ecosystem</p>
						<a href="#content">
							<Scroll />
						</a>
					</Header> */}
					<Hero
						heading={page.hh_heading}
						blurb={page.hh_blurb}
						style={{ value: 'white' }}
						showScrollToContent={false}
						// imageAlt={module.imagealt}
						// imageUrl={module.image.publicURL}
						// imageFile={module.image.imageFile}
					/>
					<Divider />
					<section id="content">
						<Fade bottom distance="1.5rem" delay={800}>
							<h2>{page.featured_content_label}</h2>
						</Fade>
						<FeaturedGrid>
							{featured.map(i => <InsightsCard key={i.id} insight={i}/>)}
						</FeaturedGrid>
					</section>
					<section>
						<Divider />
						<NonFeaturedGrid>
							{unFeatured.map((i, index) => <InsightsCard sideBySide reverse={index % 2} key={i.id} insight={i}/>)}
						</NonFeaturedGrid>
					</section>
				</Wrapper>
			{/* </Fade> */}
		</Layout>
	);
};

export default InsightsPage;

export const query = graphql`
	fragment CMSAsset on cms_Asset_Assets {
		... on cms_Asset_Assets {
			id
			alt
			focus_css
			imageFile {
				publicURL
				extension
				childImageSharp {
					gatsbyImageData(width: 4000, formats: [AUTO, WEBP, AVIF])
				}
				absolutePath
				atime
				base
			}
			path
		}
		url
		path
	}
	query OverviewInsights($siteHandle: String, $pageId: String) {
		cms {
			nav(handle: "main_nav") {
				tree(site: $siteHandle) {
					page {
						title
						url
						... on cms_NavPage_MainNav {
							external
						}
					}
					children {
						page {
							title
							url
						}
					}
				}
			}
			footer_nav: nav(handle: "footer_nav") {
				tree(site: $siteHandle) {
					page {
						title
						url
					}
					children {
						page {
							title
							url
						}
					}
				}
			}
			globalSet(handle: "footer", site: $siteHandle) {
				... on cms_GlobalSet_Footer {
					copyright
					links {
					... on cms_Set_Links_Links {
						link_link
						link_label
						link_external
						link_btn_type {
						label
						value
						}
					}
					}
				}
			}
			SeoMeta(page_id: $pageId) {
				og_title
				og_description
				description
				og_url
				rel_canonical
				translations {
					exists
					id
					is_current
					site
					uri
				}
			}
			page: entry(id: $pageId) {
				id
				title
				... on cms_Entry_Pages_PageInsightLanding {
					hh_blurb
					hh_heading
					featured_content_label
				}
			}
			entries(collection: "insights", filter: {site: $siteHandle}, limit: 5000, sort: "date desc") {
				data {
					id
					title
					... on cms_Entry_Insights_VideoInsight {
					id
					video_url
					title
					featured
					cover_image {
						...CMSAsset
					}
					blurb
					}
				}
			}
			search: globalSet(handle: "search", site: $siteHandle) {
				... on cms_GlobalSet_Search {
						handle
						results_text
						no_results_subtext
						no_results_text
						search_input_field
						companies_headline
          				investors_headline
						suggested_search_terms {
						name
					}
					site {
						handle
						name
					}
				}
			},
			team: entries(collection: "team", limit: 3000, filter: {site: $siteHandle}) {
				to
				data {
					id
					... on cms_Entry_Team_Team {
						__typename
						id
						title
						published
						site {
							short_locale
							locale
							handle
							name
			
						}
						slug
						preview_image {
							... on cms_Asset_Assets {
							id
							alt
							imageFile {
								publicURL
								extension
								childImageSharp {
								gatsbyImageData(width: 90, height: 90, formats: [AUTO, WEBP, AVIF])
								}
								absolutePath
								atime
								base
							}
							path
							}
						}
						sectors {
							... on cms_Term_Sectors_Sectors {
							id
							title
							}
						}
						region {
							id
							... on cms_Term_Region_Region {
							id
							title
							}
						}
					}
				}
				total
				from
				has_more_pages
			}
			portfolio_companies: entries(collection: "companies", sort: "title", filter: {site: $siteHandle}) {
				to
				data {
					id
					slug
					... on cms_Entry_Companies_Companies {
						__typename
						id
						title
						slug
						founder_label
						founders
						company_url
						description
						status
						status_override
						company_status {
							id
							slug
							title
						}
						site {
							short_locale
							locale
							handle
							name
			
						}
						team {
							hide_on_portfolio_page
							team_member {
								id
								title
							}
						}
						region {
							... on cms_Term_Region_Region {
								id
								title
								slug
							}
						}
						sectors {
							... on cms_Term_Sectors_Sectors {
								id
								title
								slug
							}
						}
						logo {
							... on cms_Asset_Assets {
							id
							alt
							imageFile {
								publicURL
								extension
								childImageSharp {
								gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
								}
								absolutePath
								atime
								base
							}
							path
							}
							url
							path
						}
					}
				}
				total
				from
				has_more_pages
			}
		}
	}
`