import React, { useState } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoints from "../../utils/breakpoints";
import Fade from "react-reveal/Fade";
import Button from "../button/button";
import ReactPlayer from "react-player";
import Close from "../../assets/vectors/Close";
import PlayVideo from "../../assets/vectors/PlayVideo";

const ContentSection = styled.div`
    h3 {
        font-weight: 400;
        font-size: 2.4rem;
        line-height: 120%;
        color: #333333;
        margin: 0;
        margin-bottom: 3.2rem;
		font-family: 'Magazine Grotesque';
    }
    p {
        font-family: 'Post Grotesk';
        font-weight: 300;
        font-size: 1.8rem;
        color: #807E7E;
        line-height: 2.4rem;
        margin-bottom: 6.4rem;
    }
`

const Container = styled.div`
    cursor: pointer;
    ${props => props.sideBySide ? `
        margin-bottom: 10rem;
        @media ${breakpoints.tabletSm} {
            margin-bottom: 23rem;
            display: flex;
        }
        .gatsby-image-wrapper {
            aspect-ratio: 630 / 355;
            width: 100%;
            @media ${breakpoints.tabletSm} {
                width: 50%;
            }
        }
        justify-content: space-between;
        ${ContentSection} {
            width: 100%;
            margin-top: 3.8rem;
            @media ${breakpoints.tabletSm} {
                width: 40%;
                padding-right: 8.333%;
            }
            h3 {
                font-weight: 400;
                font-size: 3.7rem;
                line-height: 120%;
            }
            p {
                margin-bottom: 3.4rem;
            }
        }
    ` : `
        ${ContentSection} {
            margin-top: 3.8rem;
        }
    `}
    ${props => props.reverse && `
        flex-direction: row-reverse;
    `}
`;

const Modal = styled.div`
    & > div {
        position: fixed;
        // width: 90vw;
        // height: 90vh;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: white;
        padding: 2rem;
        z-index: 9999;
        padding-top: 4rem;
        max-width: 90vw;
        max-height: 80vh;
        overflow: hidden;
        & > div {
            max-width: 100%;
        }
    }
    svg {
        width: 3rem;
        height: 3rem;
        position: absolute;
        right: 2rem;
        top: 2rem;
    }
    &::before {
        position: fixed; 
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        background-color: black; 
        opacity: 0.75;
        content: '';
        top: 0;
        left: 0;
    }
    
`;

export default function InsightsCard({ insight, sideBySide, reverse }) {
	  const [isModalOpen, setIsModalOpen] = useState(false);

	//   const data = useStaticQuery(graphql`
	//     query cookie {
	//       cms {
	//         globalSet(handle: "footer") {
	//           ... on cms_GlobalSet_Footer {
	//             cookie_message
	//           }
	//         }
	//       }
	//     }
	//   `)

	//   const { cookie_message } = data.cms.globalSet;

	//   const onCTAClick = () => {
	//     localStorage.setItem('SBCookieBanner', '1');
	//     setIsActive(false)
	//   }

	//   useEffect(() => {
	//     if (localStorage.getItem('SBCookieBanner')) {
	//       setIsActive(false);
	//     } else {
	//       setIsActive(true);
	//     }
	//   }, []);
	return (
        <div>
            <Fade bottom distance="1.5rem" delay={800}>
                <Container sideBySide={sideBySide} reverse={reverse} onClick={() => setIsModalOpen(true)}>
                    <GatsbyImage
                        image={getImage(insight.cover_image.imageFile)}
                        alt={`${insight.cover_image.alt ? insight.cover_image.alt : ''}`}
                    />
                    <ContentSection>
                        <h3>{insight.title}</h3>
                        <p>{insight.blurb}</p>
                        <Button onClick={() => setIsModalOpen(true)}>
                            <PlayVideo />
                            View the Video
                        </Button>
                    </ContentSection>
                </Container>
            </Fade>
            {isModalOpen && <Modal>
                <div>
                    <button onClick={() => setIsModalOpen(false)}>
                        <Close />
                    </button>
                    <ReactPlayer url={insight.video_url}/>
                </div>
            </Modal>}
        </div>
	);
}
