import React from "react";

const PlayVideo = () => (
	<svg
		width="14"
		height="16"
		viewBox="0 0 14 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M14 8L0 16L0 0L14 8Z" fill="#333333" />
	</svg>
);
export default PlayVideo;
