import React from "react";

const Scroll = () => (
	<svg
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="20" cy="20" r="20" fill="#333333" />
		<rect
			x="14.3433"
			y="19"
			width="2"
			height="8"
			transform="rotate(-45 14.3433 19)"
			fill="white"
		/>
		<rect
			x="14.3433"
			y="19"
			width="2"
			height="8"
			transform="rotate(-45 14.3433 19)"
			fill="#95C9FF"
			fillOpacity="0.08"
		/>
		<rect
			x="20.0005"
			y="24.6562"
			width="2"
			height="8"
			transform="rotate(-135 20.0005 24.6562)"
			fill="white"
		/>
		<rect
			x="20.0005"
			y="24.6562"
			width="2"
			height="8"
			transform="rotate(-135 20.0005 24.6562)"
			fill="#95C9FF"
			fillOpacity="0.08"
		/>
	</svg>
);
export default Scroll;
