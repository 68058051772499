import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { withPrefix } from "gatsby";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import parse from "html-react-parser";
import Scroll from "../../assets/vectors/Scroll";
import { H1 } from "../typography";
import breakpoints from "../../utils/breakpoints";

const Column = styled.div`
	width: 100%;
	@media ${breakpoints.tablet} {
		width: 50%;
	}
`;

const Foreground = styled.div`
	position: relative;
	display: block;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: row;
	z-index: 2;
	margin-top: auto;
	color: #fff;
	padding: 0 calc(var(--grid-padding) * 0.75) 6rem;
	width: 100%;

	.react-reveal {
		opacity: 0;
	}

	@media ${breakpoints.tablet} {
		display: flex;
		padding: 0 calc(var(--grid-padding)) calc(var(--grid-padding) * 2);
	}

	@media ${breakpoints.desktop} {
		padding: 0 calc(var(--grid-padding) * 1.83) calc(var(--grid-padding) - 1rem);
	}

	h1 {
		font-family: var(--font-secondary);
		font-size: 4.5rem;
		line-height: 1.11em;
		font-weight: 400;
		display: inline-block;
		letter-spacing: -3px;
		padding-right: 1rem;
		span {
			display: inline;

			@media ${breakpoints.desktopSm} {
				display: block;
			}
		}
		@media ${breakpoints.tabletSm} {
			font-size: 5rem;
		}

		@media ${breakpoints.tablet} {
			font-size: 6rem;
		}

		@media ${breakpoints.desktopSm} {
			font-size: 7rem;
			line-height: 1.08em;
		}

		@media ${breakpoints.desktop} {
			font-size: 8rem;
		}

		@media ${breakpoints.monitor} {
			font-size: 10rem;
		}
	}
`;

const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.react-reveal {
		opacity: 0;
	}

	> span {
		display: block;
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--brand-gray);
	}
`;

const BlurbWrap = styled.div`
	font-family: var(--font-primary);
	margin-top: 2rem;
	font-size: 1.8rem;
	line-height: 2.4rem;
	font-weight: 200;
	margin-bottom: 0;
	letter-spacing: -0.20000000298023224px;
	margin-left: 0rem;
	display: none;

	@media ${breakpoints.tablet} {
		display: block;
		margin-top: 2rem;
		margin-right: 10%;
		font-size: 2.4rem;
		line-height: 3.4rem;
		margin-right: 10rem;
		margin-left: 2rem;
	}
	@media ${breakpoints.desktop} {
		margin-right: 12%;
		font-size: 2.6rem;
		line-height: 3.6rem;
	}

	strong {
		font-weight: 500;
	}
`;

const BlurbWrapMobile = styled.div`
	font-family: var(--font-primary);
	margin-top: 2rem;
	font-size: 1.8rem;
	line-height: 2.4rem;
	font-weight: 300;
	margin-bottom: 0;
	letter-spacing: -0.20000000298023224px;
	margin-left: 0rem;
	display: block;

	@media ${breakpoints.tablet} {
		display: none;
		margin-top: 3rem;
		margin-right: 10%;
		font-size: 2.4rem;
		line-height: 3.4rem;
		margin-right: 10rem;
		margin-left: 2rem;
	}
	strong {
		font-weight: 500;
	}
`;

const Container = styled.div`
	position: relative;
	padding-top: ${props => (props.theme === "light" ? "calc(20rem - "+props.headeroffset+"px)" : "20rem")};

	@media only screen and (min-width: 640px) and (orientation: landscape) {
		padding-top: 32.55208vw;
	}
	@media only screen and (min-width: 640px) and (orientation: portrait) {
		padding-top: 50.78125vw;
	}

	@media ${breakpoints.tablet} {
		padding-top: ${props => (props.theme === "light" ? "calc(26.04167vw - "+props.headeroffset+"px)" : "26.04167vw")};
	}
	@media ${breakpoints.desktop} {
		padding-top: ${props => (props.theme === "light" ? "calc(22vw - "+props.headeroffset+"px)" : "22vw")};
		
	}

	&.reduced {
		@media ${breakpoints.tablet} {
			margin-bottom: 5.98958vw;
			padding-top: 14.58333vw;
		}
		@media ${breakpoints.desktop} {
			padding-top: 28rem;
			margin-bottom: 11.5rem;
		}

		.btn {
			display: none;
		}
	}

	&.white {
		${Foreground} {
			${H1} {
				color: var(--brand-gray);
				background: linear-gradient(90deg, #e91711 5.69%, #efd608 106.72%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				letter-spacing: -3px;
			}
			${BlurbWrap} {
				color: var(--brand-gray);
			}
			${BlurbWrapMobile} {
				color: var(--brand-gray);
			}
		}
		${Background} {
			> span {
				background-color: var(--brand-white);
			}
		}
	}
`;

const ScrollToContent = styled.a`
	display: block;
	margin-top: 3rem;
`;

export default function Hero({
	heading,
	blurb,
	blurb_mobile,
	style,
	reduced,
	showScrollToContent,
	theme,
	mobileTheme
}) {
	const mainRef = useRef();
	const [headerOffset, setHeaderOffset] = useState(0);

	useLayoutEffect(() => {
	  let header = document.querySelector('header');
	  if (header) {
		setHeaderOffset(header.offsetHeight);
	  }
	}, []);
	useEffect(() => {
		let header = document.querySelector('header');
		const onResize = () => {
		  if (header) {
			setHeaderOffset(header.offsetHeight);
		  }
		}
		window.addEventListener("resize", onResize);
		return () => {
			window.removeEventListener("resize", onResize);
		}
	  }, []);
	return (
		<Container ref={mainRef} className={style?.value} theme={theme} mobiletheme={mobileTheme} headeroffset={headerOffset}>
			<Foreground>
				<Column>
					<Fade bottom distance="1.5rem" delay={800}>
						<H1>{parse(heading || "")}</H1>
					</Fade>
				</Column>
				<Column>
					<Fade bottom distance="1.5rem" delay={800}>
						<BlurbWrap>{parse(blurb || "")}</BlurbWrap>
						<BlurbWrapMobile>
							{parse(blurb_mobile || parse(blurb || ""))}
						</BlurbWrapMobile>
					</Fade>
				</Column>
				{showScrollToContent && (
					<Fade bottom distance="1.5rem" delay={800}>
						<ScrollToContent href="#content">
							<Scroll />
						</ScrollToContent>
					</Fade>
				)}
			</Foreground>
			<Background>
				<Fade delay={500} duration={2000}>
					<span></span>
				</Fade>
			</Background>
		</Container>
	);
}
