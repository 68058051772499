import styled from "styled-components"

const Button = styled.button`
    padding: 1.85rem;
    border: 1px solid #807E7E;
    display: flex;
    gap: 2.4rem;
    justify-content: center;
    align-items:center;

    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.1rem;
`

export default Button